import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from '../../config/AuthConfig';

const useAuthCheck = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await userManager.getUser();
        const now = Math.floor(Date.now() / 1000);

        if (!user || user.expired || user.expires_at < now) {
          try {
            await userManager.signinSilent();
          } catch (silentError) {
            console.error("Silent sign-in failed:", silentError);
            if (window.location.pathname !== '/callback') {
              userManager.signinRedirect();
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (window.location.pathname !== '/callback') {
          userManager.signinRedirect();
        }
      }
    };

    checkAuth();
  }, [navigate]);
};

export default useAuthCheck;
