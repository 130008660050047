import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from '../../config/AuthConfig';
import LoadingBounces from '../util/LoadingBounces';

const Callback = () => {
  const navigate = useNavigate();
  const [isHandled, setIsHandled] = useState(false);

  useEffect(() => {
    if (isHandled) return;

    setIsHandled(true);
    console.log("userManager: ", userManager);
    userManager.signinRedirectCallback()
      .then(user => {
        console.log('User logged in:', user);
        navigate('/');
      }).catch(err => {
        console.error('Error during signinRedirectCallback:', err);
        userManager.signinSilent().catch((error) => {
          console.error('Silent renew error:', error);
          userManager.signinRedirect();
        });
      });
  }, [isHandled, navigate]);

  return (
    <div className="App">
      <div>
        <LoadingBounces />
      </div>
    </div>
  );
};

export default Callback;
