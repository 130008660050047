import React from 'react'
import { useTranslation } from 'react-i18next';

export default function LoadingBounces() {
  const { t } = useTranslation("global");
  return (
    <div className="loader">
      <div className="frame">
        <div>
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
        <div className="text"><span className="p paragraph-f">{t("loader.charging")}</span></div>
      </div>
    </div>
  )
}
