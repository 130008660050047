import { useState, useRef } from 'react';

const useOtpChange = (numInputs) => {
  const [otpValue, setOtpValue] = useState('');
  const [maskedOtp, setMaskedOtp] = useState(Array(numInputs).fill(false));
  const otpValueRef = useRef(otpValue);
  const maskedOtpRef = useRef(maskedOtp);

  const handleOtpChange = (otp, index) => {
    const updatedOtp = otpValue.substring(0, index) + otp + otpValue.substring(index + 1);
    setOtpValue(updatedOtp);
    otpValueRef.current = updatedOtp;

    const inputs = document.getElementsByClassName("otp-input");
    if (index > otpValue.length) {
      index = otpValue.length;
      inputs[index].focus();
    }

    let updatedMasked = [...maskedOtpRef.current];
    updatedMasked[index] = false;
    setMaskedOtp(updatedMasked);
    maskedOtpRef.current = updatedMasked;

    if (otp !== '') {
      setTimeout(() => {
        if (otpValueRef.current.substring(index, index + 1) !== '') {
          updatedMasked = [...maskedOtpRef.current];
          updatedMasked[index] = true;
          setMaskedOtp(updatedMasked);
          maskedOtpRef.current = updatedMasked;
        }
      }, 3000);

      if (index < numInputs - 1) {
        inputs[index + 1].focus();
      }
    }
  };

  return { otpValue, maskedOtp, handleOtpChange };
};

export default useOtpChange;
