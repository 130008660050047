import { useEffect, useCallback } from 'react';
import { sendOtpRequest } from '../../api/OTPRequest';
import { usePublicKeyDPContext, useUserContext } from '../../provider/LoginProvider';
import config from '../../config/config.json'

const useOtpRequest = (showAlert) => {
  const { publicKey } = usePublicKeyDPContext();
  const { user } = useUserContext();

  const requestOtp = useCallback(async () => {
    if (!user || !publicKey) return;

    try {
      const dinersUrl = config.dinersUrl;
      await sendOtpRequest(user, dinersUrl, publicKey);
    } catch (error) {
      console.error('Error sending OTP:', error);
      showAlert("error.unknown.title", "error.unknown.message");
    }
  }, [user, publicKey, showAlert]);

  useEffect(() => {
    requestOtp();
  }, [requestOtp]);

  return requestOtp;
};

export default useOtpRequest;
