import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config/config.json'

const FooterBar = () => {
    const { t } = useTranslation("global");

    return (
        <div className="footerBar">
            <p>
                <a
                    href={config.moreInformationUrl}
                    id="buttonForget"
                    className="button button--type-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>{t("footer-bar.more-information")}</span>
                </a>
                {t("footer-bar.copyright")}<br />
                {t("footer-bar.autor-rights")}
            </p>
        </div>
    );
};

export default React.memo(FooterBar);