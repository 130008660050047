import { useCallback } from 'react';
import { closeSession } from '../../api/UserRequest';
import userManager from '../../config/AuthConfig';
import { useUserContext, usePublicKeyDPContext } from '../../provider/LoginProvider';
import config from '../../config/config.json'

const useHomePageAuth = (setIsLoading) => {
  const { user } = useUserContext();
  const { publicKey } = usePublicKeyDPContext();

  const handleLogout = useCallback(async () => {
    setIsLoading(true);

    try {
      if (user && publicKey) {
        const dinersUrl = config.dinersUrl;
        await closeSession(user, publicKey, dinersUrl);
      }
    } catch (error) {
      console.error('Error closing session:', error);
    } finally {
      userManager.revokeAccessToken();
      userManager.removeUser();
      userManager.signinRedirect();
    }
  }, [user, publicKey, setIsLoading]);

  return { handleLogout };
};

export default useHomePageAuth;
