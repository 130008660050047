import useOtpRequest from './useOtpRequest';
import useOtpChange from './useOtpChange';
import useOtpSubmit from './useOtpSubmit';
import useDeviceInfo from '../useDeviceInfo';

const useOtpManagement = (numInputs, showLoading, showAlert) => {
  useOtpRequest(showAlert);
  const { otpValue, maskedOtp, handleOtpChange } = useOtpChange(numInputs);
  const deviceInfo = useDeviceInfo();

  const otpSubmitContext = {
    numInputs,
    showLoading,
    showAlert,
    otpValue,
    deviceInfo
  };
  const { isOtpInvalid, handleSubmit, setIsOtpInvalid } = useOtpSubmit(otpSubmitContext);

  const handleKeyDown = (e, index) => {
    if (isOtpInvalid) {
      setIsOtpInvalid(false);
    }
    if (e.key === 'Backspace' && !otpValue[index]) {
      handleOtpChange('', index);
    } else if (e.key !== 'Backspace' && otpValue[index] && index < numInputs - 1) {
      document.getElementsByClassName("otp-input")[index + 1].focus();
    }
  };

  return {
    otpValue,
    maskedOtp,
    isOtpInvalid,
    handleOtpChange,
    handleKeyDown,
    handleSubmit
  };
};

export default useOtpManagement;
