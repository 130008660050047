export class UserUnauthorizedError extends Error {
    constructor(message) {
        super(message);
        this.name = 'UserUnauthorizedError';
    }
}


export class UserAnalyticCodeError extends Error {
    constructor(message) {
        super(message);
        this.name = 'UserAnalyticCodeError';
    }
}