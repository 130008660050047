import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getSingleSelectCustomer } from '../../api/UserRequest';
import { analyticsRedirect } from '../../api/AnalyticsRequest';
import { getUserInfo } from '../../util/UserUtils';
import { useUserContext, usePublicKeyDPContext } from '../../provider/LoginProvider';
import { UserUnauthorizedError, UserAnalyticCodeError } from '../../errors/CustomErrors';
import config from '../../config/config.json'

const useHomePageUserData = (context) => {
  const { publicKey } = usePublicKeyDPContext();
  const { setUser } = useUserContext();
  const { setIsLoading, showAlert } = context;

  const updateUserData = useCallback(async (userAccess, deviceInfo) => {
    if (!deviceInfo || !publicKey || !userAccess) return;

    try {
      setIsLoading(true);
      const dinersUrl = config.dinersUrl;
      const userData = await getSingleSelectCustomer(userAccess, publicKey, deviceInfo, dinersUrl);
      const userInfo = getUserInfo(userData, userAccess);

      if (userData.device?.active) {
        await analyticsRedirect(userInfo, dinersUrl, publicKey);
      }

      setUser(userInfo);
      return true;
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      if (error instanceof UserUnauthorizedError) {
        showAlert("error.commerce.title", "error.commerce.message");
      } else if (error instanceof UserAnalyticCodeError) {
        showAlert("error.analytic-code.title", "error.analytic-code.message", false);
      } else {
        showAlert("error.unknown.title", "error.unknown.message");
      }
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [publicKey, setUser, setIsLoading, showAlert]);

  return useMemo(() => ({ updateUserData }), [updateUserData]);
};

useHomePageUserData.propTypes = {
  context: PropTypes.shape({
    setIsLoading: PropTypes.func.isRequired,
    setIsOtpModalOpen: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired
  }).isRequired
};

export default useHomePageUserData;