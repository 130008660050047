import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

const AlertModal = ({ show, title, message, primaryAction, secondaryAction }) => {
    return (
        <Modal show={show}>
            <div className="alert-modal--type-error">
                <div className="alert-modal__header">
                    <div className="alert-modal__icon">
                        <svg style={{ display: 'inline-block', verticalAlign: 'middle' }} width="50px" height="50px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1011.295 891.259l-466.299-833.422c-6.459-10.454-17.347-17.64-29.979-18.949l-0.172-0.014-6.637-0.948-6.637 1.896c-9.838 2.859-17.843 9.32-22.657 17.826l-0.098 0.189-466.11 833.422c-3.102 5.377-4.931 11.825-4.931 18.701 0 0.092 0 0.184 0.001 0.276l-0-0.014c0 20.946 16.98 37.926 37.926 37.926v0h932.599c0.024 0 0.053 0 0.081 0 17.3 0 31.895-11.584 36.451-27.418l0.066-0.268c0.909-3.093 1.431-6.646 1.431-10.321 0-6.949-1.869-13.462-5.132-19.062l0.097 0.181zM78.127 891.259l433.873-776.154 433.873 774.827z"></path>
                            <path d="M483.556 396.895v265.481c0 15.709 12.735 28.444 28.444 28.444s28.444-12.735 28.444-28.444v0-265.481c0-15.709-12.735-28.444-28.444-28.444s-28.444 12.735-28.444 28.444v0z"></path>
                            <path d="M512 735.953c-15.709 0-28.444 12.735-28.444 28.444v0 4.551c0 15.709 12.735 28.444 28.444 28.444s28.444-12.735 28.444-28.444v0-4.551c0-15.709-12.735-28.444-28.444-28.444v0z"></path>
                        </svg>
                    </div>
                    <div className="alert-modal__title">
                        <span className="h1 h1--a">{title}</span>
                    </div>
                </div>
                <div className="alert-modal__text">
                    <span className="p paragraph-a">{message}</span>
                    <br /><br />
                </div>
                <div className="alert-modal__footer">
                    <div>
                        <button id="alertHere" className="button button--type-primary-a" onClick={primaryAction.onClick} type="button">
                            <span className="p paragraph-j">{primaryAction.text}</span>
                        </button>
                    </div>
                    {secondaryAction ?
                        <div>
                            <button id="alertCancel" className="button button--type-link" onClick={secondaryAction.onClick} type="button">
                                <span className="p paragraph-l">{secondaryAction.text}</span>
                            </button>
                        </div> : ''
                    }
                </div>
            </div>
        </Modal>
    );
};

AlertModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    primaryAction: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired
    }).isRequired,
    secondaryAction: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired
    })
};


export default AlertModal;
