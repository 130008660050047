import forge from 'node-forge';
import CryptoJS from 'crypto-js';
import { JSEncrypt } from 'jsencrypt';

export const generateRandomKey = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('');
};

export const generateLocalKeyPair = () => {
    const keyPair = forge.pki.rsa.generateKeyPair(2048);
    return keyPair;
};

const NODE_FORGE_PADDINGS = {
    RSA: {
        RSA_OAEP: 'RSA-OAEP',
        RSA_PKCS1_V1_5: 'RSAES-PKCS1-V1_5'
    },
    AES: {
        AES_CBC: 'AES-CBC',
        AES_GCM: 'AES-GCM'
    }
};

export const forgeRSAEncrypt = (
    publicKeyParam,
    inputData,
    scheme = NODE_FORGE_PADDINGS.RSA.RSA_QAEP,
    hashOptions = {
        md: forge.md.sha256.create(),
        mgf1: {
            md: forge.md.sha256.create()
        }
    }
) => {
    return forge.util.encode64(
        publicKeyParam.encrypt(inputData, scheme, hashOptions)
    );
}
const forgeAESEncrypt = (
    secretKey,
    iv,
    inputData,
    scheme = NODE_FORGE_PADDINGS.AES.AES_CBC
) => {
    const data = forge.util.createBuffer(inputData, 'utf8');
    const decipher = forge.cipher.createCipher(scheme, secretKey);
    decipher.start({ iv });
    decipher.update(data);
    if (decipher.finish()) {
        return forge.util.encode64(decipher.output.getBytes());
    }
    return null;
}


export const getRandomString = (
    length,
    charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
) => {
    var result = '';
    for (var i = length; i > 0; --i)
        result += charset[Math.floor(Math.random() * charset.length)];
    return result;
};

export const encryptRequest = (payload, publicKeyParam, localkeys) => {
    const publicKey = forge?.pki?.publicKeyFromPem(
        `-----BEGIN PUBLIC KEY-----\r\n${publicKeyParam}\r\n-----END PUBLIC KEY-----`
    );

    const iv = getRandomString(16);
    const key = getRandomString(32);


    const localPublicKey = forge.pki.publicKeyToPem(localkeys.publicKey);
    const plainLocalPublicKey = localPublicKey
        .replace('-----BEGIN PUBLIC KEY-----', '')
        .replace('-----END PUBLIC KEY-----', '')
        .replace(/(\r\n|\n|\r|\s)/gm, '');

    return {
        body: forgeAESEncrypt(key, iv, JSON.stringify(payload)),
        keyRSA: plainLocalPublicKey,
        secretKey: `${forgeRSAEncrypt(publicKey, key, NODE_FORGE_PADDINGS.RSA.RSA_PKCS1_V1_5)}|${forge.util.encode64(iv)}`
    }
};

const forgeRSADecrypt = (privateKey, encryptedData) => {
    return privateKey.decrypt(forge.util.decode64(encryptedData), NODE_FORGE_PADDINGS.RSA.RSA_PKCS1_V1_5);
};

const forgeAESDecrypt = (secretKey, iv, encryptedData, scheme = NODE_FORGE_PADDINGS.AES.AES_CBC) => {
    const decipher = forge.cipher.createDecipher(scheme, secretKey);
    const data = forge.util.decode64(encryptedData);
    decipher.start({ iv });
    decipher.update(forge.util.createBuffer(data));
    const result = decipher.finish();
    if (result) {
        return decipher.output.toString('utf8');
    }
    return null;
};

export const decryptResponse = (encryptedResponse, privateKey) => {
    const { body, keyAES } = encryptedResponse;
    const [encryptedSymmetricKey, ivBase64] = keyAES.split('|');

    const symmetricKey = forgeRSADecrypt(privateKey, encryptedSymmetricKey);
    const iv = ivBase64;

    const decryptedBody = forgeAESDecrypt(symmetricKey, iv, body);
    return decryptedBody;
};

export const getAesKey = () => {
    return String(CryptoJS.lib.WordArray.random(16));
}

export const publicKeyToPem = (publicKey) => {
    return forge.pki.publicKeyToPem(publicKey); 
}

export const encrypAES_ECB = (data, key = null) => {
  const simetricKey = CryptoJS.enc.Utf8.parse(key === null ? getAesKey() : key);

  if (data && typeof data !== 'string') {
    return Object.keys(data).reduce((obj, valueKey) => {
      obj[valueKey] = encrypAES_ECB(data[valueKey]);
      return obj;
    }, {});
  }

  const encryptedData = CryptoJS.AES.encrypt(data, simetricKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return String(encryptedData);
}

export const rsaEncryptText = (publicKey, text) => {
  if (text && publicKey) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encryptedValue = String(encrypt.encrypt(text));
    return encryptedValue;
  }
  return '';
}