import { UserManager, WebStorageStateStore } from 'oidc-client';


const loginUrl = process.env.REACT_APP_LOGIN_URL;
const appUrl = process.env.REACT_APP_URL;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

const settings = {
  authority: loginUrl,
  client_id: 'merchants_web',

  redirect_uri: `${appUrl}/callback`,
  response_type: "code",
  response_mode: 'query',
  scope: "openid profile email",
  post_logout_redirect_uri: appUrl,

  // stateStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: sessionStorage }),
  code_challenge_method: 'S256',
  silent_redirect_uri: appUrl + '/silent-renew.html',
  silentRequestTimeout: 10000,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  client_secret: clientSecret
};

const userManager = new UserManager(settings);

userManager.events.addAccessTokenExpiring(() => {
  console.log('Access token expiring...');
  userManager.signinSilent().catch((error) => {
    console.error('Silent renew error:', error);
  });
});

export default userManager;
