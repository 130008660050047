import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import OtpInput from 'react-otp-input';
import useOtpManagement from '../../hooks/otp/useOtpManagement';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const OtpModal = ({ isOpen, onRequestClose, user, cancelAction, showLoading, showAlert }) => {
  const { t } = useTranslation("global");
  const numInputs = 6;
  const {
    otpValue,
    maskedOtp,
    isOtpInvalid,
    handleOtpChange,
    handleKeyDown,
    handleSubmit
  } = useOtpManagement(numInputs, showLoading, showAlert);
  const [ipV4, setIpV4] = useState('');

  useEffect(() => {
    const fetchIpV4 = async () => {
      try {
        const publicIp = require("react-public-ip");
        const ip = await publicIp.v4();
        setIpV4(ip);
      } catch (error) {
        console.error('Failed to fetch IP address:', error);
      }
    };
    fetchIpV4();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t("otp.title")}
      portalClassName="modal"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <form onSubmit={handleSubmit}>
        <span className="h1 h1--a">{t("otp.title")}</span>
        <div className='otp-container'>
          <div className="otp-description">
            <span className="p paragraph-a">
              <Trans i18nKey="global:otp.description"
                values={{ cellphone: user.otp.cellphone, mail: user.otp.mail }}
                components={{ b: <b /> }} />
            </span>
            <span className="p paragraph-a">{t("otp.expires")}</span>
          </div>
          <div className='containerOTP'>
            <OtpInput
              value={otpValue}
              onChange={(otp) => {
                const index = otp.length - 1;
                handleOtpChange(otp[index], index);
              }}
              numInputs={numInputs}
              isInputNum={true}
              focusStyle={{ border: '1px solid blue' }}
              renderInput={(props, index) => (
                <input
                  {...props}
                  className={(`otp-input ${isOtpInvalid ? 'otp-input-status-invalid' : ''}`)}
                  type="text"
                  maxLength="1"
                  value={maskedOtp[index] ? '*' : props.value}
                  onChange={(e) => { if (e.key !== 'Backspace') { handleOtpChange(e.target.value, index) } }}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              )}
              shouldAutoFocus
            />
            {isOtpInvalid && (
              <div className="label-otp"><span>{t("otp.tmp-error")}</span></div>
            )}
          </div>
          <div>
            <div className="otp-ip-address">
              <span className="h3 h3--type-a">{t("otp.access-ip")}</span>
              <span className="p paragraph-a">{ipV4 || t("otp.loading-ip")}</span>
            </div>
            <div className="otp-trusted-device">
              <label htmlFor="otpAcceptDevice" className="checkbox">
                <input id="otpAcceptDevice" name="otpAcceptDevice" type="checkbox" />
                <span className="slider span-check"></span>
                <span style={{display: 'none'}}>*</span>
              </label>
              <span className="p paragraph-i">{t("otp.trusted-device")}</span>
            </div>
          </div>
        </div>
        <div className="otp-buttons">
          <button id="otpButtonAccept" className="button button--type-primary-a" tabIndex="0" type="submit" disabled={otpValue.length !== numInputs}>
            <span className="p paragraph-j">{t("button.continue")}</span>
          </button>
          <button id="otpButtonCancel" className="button button--type-link" tabIndex="0" type="button" onClick={cancelAction}>
            <span className="p paragraph-l">{t("button.cancel")}</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};

OtpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    establishment: PropTypes.shape({
      ruc: PropTypes.string.isRequired,
      businessName: PropTypes.string.isRequired
    }),
    otp: PropTypes.shape({
      mail: PropTypes.string.isRequired,
      cellphone: PropTypes.string.isRequired
    }),
    customer: PropTypes.shape({
      customerId: PropTypes.string.isRequired,
      userProfile: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      additionalCustomerType: PropTypes.shape({
        customerTypeId: PropTypes.number.isRequired,
        officialId: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  cancelAction: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired
};

export default OtpModal;
