import axios from 'axios';
import authService from './AuthService';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config) => {
        const user = await authService.getUser();
        if (user?.access_token && user?.profile) {
            config.headers['access_token'] = user.access_token;
            config.headers['id_token'] = user.id_token;
            config.headers['organization_operator_id'] = `userName=${user.profile.preferred_username};`;
        }
        return config;
    },
    (error) => Promise.reject(error instanceof Error ? error : new Error(String(error)))
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if ((error.response.status === 401 || error.response.status === 400) && !originalRequest._retry) {
            originalRequest._retry = true;
            const now = Math.floor(Date.now() / 1000);

            const user = await authService.getUser();
            if (!user || user.expired || user.expires_at < now) {
                await authService.renewToken();
            }
        }
        return Promise.reject(error instanceof Error ? error : new Error(String(error)));
    }
);

export default axiosInstance;