import { generateHeaders, getRestObject, REST_OBJECT_TYPE, postEncryptRequest } from './ApiService';
import { generateLocalKeyPair, encrypAES_ECB, rsaEncryptText, getAesKey, publicKeyToPem } from '../util/CryptoManagement';

export const setTrustDevice = async (user, publicKey, deviceInfo, dinersUrl) => {
    const headers = generateHeaders();
    let response = null;

    console.log(user);
    try {
        const keyPair = generateLocalKeyPair();
        const aesKey = getAesKey();
        const encryptedAesKey = rsaEncryptText(publicKey, aesKey);

        const encryptedUsername = encrypAES_ECB(user.userName, aesKey);

        headers['llave_simetrica'] = encryptedAesKey;

        const data = {
            customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, {
                customerId: user.establishment.ruc,
                additionalCustomerId: user.customer.additionalCustomerType.officialId,
                additionalCustomerType: {
                    customerTypeId: user.customer.customerId?.split('#')[0]
                },
                profilingTransaction: "ADD",
                systemUser: { userName: encryptedUsername }
            }),
            profile: getRestObject(REST_OBJECT_TYPE.PROFILE, { profileType: { mnemonic: user.customer.userProfile }}),
            device: getRestObject(REST_OBJECT_TYPE.DEVICE, { sDeviceId: deviceInfo }),
            keyRSA: publicKeyToPem(keyPair.publicKey)
        };

        console.log("request Devicetask", data);
        response = await postEncryptRequest(`${dinersUrl}/users/insertTaskDevice/`, publicKey, data, headers, keyPair);
        console.log("response devicetask", response);
    } catch (error) {
        console.error(error);
    }
    return response;
};

export const userExtract = () => {
    return null;
}