import React from 'react';
import OtpModal from '../../components/otp/OtpModal';
import LoadingBounces from '../../components/util/LoadingBounces';
import AlertModal from '../../components/modal/AlertModal';
import useHomePage from '../../hooks/home/useHomePage';
import { useTranslation } from 'react-i18next';
import { useUserContext, usePublicKeyDPContext } from '../../provider/LoginProvider';
import FooterBar from '../../components/home/FooterBar';

const HomePage = () => {
  const { t } = useTranslation("global");
  const { user } = useUserContext();
  const { publicKey } = usePublicKeyDPContext();
  const {
    isOtpModalOpen,
    isLoading,
    alertDataModal,
    isBlocked,
    handleLogout,
    showAlert,
    setIsOtpModalOpen,
    setIsLoading
  } = useHomePage();

  return (
    <div className="App">
      {alertDataModal && (
        <AlertModal
          show={true}
          title={alertDataModal.title}
          message={alertDataModal.message}
          primaryAction={{ text: t("button.understood"), onClick: handleLogout }}
        />
      )}

      {!isBlocked && user && publicKey && (
        <OtpModal
          isOpen={isOtpModalOpen}
          onRequestClose={() => setIsOtpModalOpen(false)}
          user={user}
          cancelAction={handleLogout}
          showLoading={setIsLoading}
          showAlert={showAlert}
        />
      )}

      {isLoading && <LoadingBounces />}
      <FooterBar />
    </div>
  );
};

export default HomePage;