import { UserUnauthorizedError } from "../errors/CustomErrors";

const isObject = (value) => value && typeof value === 'object';

const validateEstablishment = (establishment) => {
  if (!isObject(establishment) || !establishment.ruc || !establishment.businessName) {
    throw new UserUnauthorizedError('Invalid or incomplete establishment data');
  }
};

const validateCustomer = (customer) => {
  if (!isObject(customer)) {
    throw new Error('Customer data is not a valid object');
  }

  if (customer.isEstablishment !== true) {
    throw new UserUnauthorizedError('Invalid or incomplete establishment data');
  }

  if (!customer.customerId || !customer.userProfile || !customer.customerUserName ||
    !customer.additionalCustomerType || (!customer.OTPMail && !customer.OTPCellphone)) {
    throw new Error('Invalid or incomplete customer data');
  }
};

export const validateUserData = (userData) => {
  if (!userData) {
    throw new Error('userData is null or undefined');
  } else if (userData?.Detail) {
    throw new Error(`User data validation failed: ${userData.Detail?.errors?.error?.cmmMsg}`);
  }

  const { establishment, customer } = userData;
  validateEstablishment(establishment);
  validateCustomer(customer);

  return true;
};

export const getUserInfo = (userData, userAccess) => {
  validateUserData(userData);
  return {
    ...userAccess,
    establishment: {
      ruc: `${userData.establishment.ruc}`,
      businessName: userData.establishment.businessName
    },
    otp: {
      mail: userData.customer.OTPMail,
      cellphone: userData.customer.OTPCellphone
    },
    customer: {
      customerId: userData.customer.customerId,
      userProfile: userData.customer.userProfile,
      userName: userData.customer.customerUserName,
      additionalCustomerType: userData.customer.additionalCustomerType
    }
  };
};
