import { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Bowser from 'bowser';

const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState('');

  useEffect(() => {
    const generateDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      const adBlock = await detectAdBlock();
      const userAgent = navigator.userAgent;
      const bowser = Bowser.getParser(userAgent);
      const browserInfo = getBrowserInfo(result, adBlock, bowser);

      const systemInfoBrowser = {
        name: bowser.getBrowserName(),
        version: bowser.getBrowserVersion(),
        major: bowser.getBrowserVersion().split('.')[0]
      };

      const fullDeviceInfo = {
        fingerprint: result.visitorId,
        fingerprintInfo: { ...browserInfo },
        systemInfo: {
          ua: userAgent,
          browser: systemInfoBrowser,
          engine: getEngine(userAgent),
          os: getOsInfo(bowser),
          device: {},
          cpu: {
            architecture: getCPUArchitecture(userAgent)
          }
        }
      };

      const encodedDeviceId = btoa(JSON.stringify(fullDeviceInfo));
      setDeviceInfo(encodedDeviceId);
    };

    generateDeviceId();
  }, []);

  return deviceInfo;
};

const detectAdBlock = () => {
  return new Promise((resolve) => {
    const testAd = document.createElement('div');
    testAd.innerHTML = '&nbsp;';
    testAd.className = 'adsbox';
    document.body.appendChild(testAd);
    window.setTimeout(() => {
      if (testAd.offsetHeight === 0) {
        resolve(true);
      } else {
        resolve(false);
      }
      testAd.remove();
    }, 100);
  });
};

const getScreenInfo = () => {
  return {
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    availableHeight: window.screen.availHeight,
    availableWidth: window.screen.availWidth
  };
}

const getBrowserInfo = (result, adBlock, bowser) => {
  const canvasFingerprint = "d4d36b9c";
  const webGLFingerprint = "ba62b7a9";
  const screenInfo = getScreenInfo();
  return {
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth,
    timezoneOffset: new Date().getTimezoneOffset(),
    hasSessionStorage: 'sessionStorage' in window && window.sessionStorage !== null,
    hasLocalStorage: 'localStorage' in window && window.localStorage !== null,
    hasIndexedDb: 'indexedDB' in window && window.indexedDB !== null,
    hasAddBehavior: 'addBehavior' in document,
    hasOpenDatabase: 'openDatabase' in window,
    navigatorCpuClass: 'navigatorCpuClass: ' + (navigator.cpuClass || 'unknown'),
    navigatorPlatform: 'navigatorPlatform: ' + (navigator?.userAgentData?.platform),
    doNotTrack: 'doNotTrack: ' + (navigator.doNotTrack || 'unknown'),
    plugins: Array.from(result.components.plugins.value).map(plugin => {
      const pluginMimeTypes = Array.from(plugin.mimeTypes).map(mimeType => `${mimeType.type}~${mimeType.suffixes}`).join(',');
      return `${plugin.name}::${plugin.description}::${pluginMimeTypes}`;
    }).join(";"),
    hasAdBlock: adBlock,
    hasLiedLanguages: (navigator.language || navigator.userLanguage) !== (navigator.languages[0]),
    hasLiedResolution: screenInfo.screenHeight !== screenInfo.availableHeight || screenInfo.screenWidth !== screenInfo.availableWidth,
    hasLiedOs: bowser.getOSName() !== 'unknown',
    fonts: Array.from(result.components.fonts.value).join(";"),
    crcCanvas: canvasFingerprint,
    crcWebGl: webGLFingerprint,
    screenResolutionInfo: screenInfo
  }
};

const getEngine = (userAgent) => {
  let engineName = null;
  let engineVersion = null;
  if (userAgent.includes("AppleWebKit")) {
    engineName = "WebKit";
    const matches = userAgent.match(/AppleWebKit\/([\d.]+)/);
    if (matches && matches.length > 1) {
      engineVersion = matches[1];
    }
  } else if (userAgent.includes("Blink")) {
    engineName = "Blink";
    const matches = userAgent.match(/Chrome\/([\d.]+)/);
    if (matches && matches.length > 1) {
      engineVersion = matches[1];
    }
  } else if (userAgent.includes("Gecko")) {
    engineName = "Gecko";
    const matches = userAgent.match(/rv:([\d.]+)/);
    if (matches && matches.length > 1) {
      engineVersion = matches[1];
    }
  }
  return {
    name: engineName,
    version: engineVersion
  }
}

const getOsInfo = (bowser) => {
  const rawOSVersion = bowser.getOSVersion();
  let formattedOSVersion = rawOSVersion;
  const matches = rawOSVersion.match(/\d+/);
  if (matches) {
    formattedOSVersion = matches[0];
  }
  return {
    name: bowser.getOSName(),
    version: formattedOSVersion
  };
}

const getCPUArchitecture = (userAgent) => {
  if (userAgent.includes("WOW64") || userAgent.includes("Win64") || userAgent.includes("x64")) {
    return "amd64";
  }
  if (userAgent.includes("ARM")) {
    return "arm";
  }
  return "unknown";
};

export default useDeviceInfo;
