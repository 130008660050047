import '../../assets/css/modal.css';
import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ show, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal__overlay"></div>
            <div className="modal__view modal--fade-in">
                <div className="modal__content">
                    {children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired
};

export default Modal;