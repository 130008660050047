import { generateHeaders, getRestObject, REST_OBJECT_TYPE, postEncryptRequest } from './ApiService';

export const sendOtpRequest = async (user, dinersUrl, publicKey) => {
  const headers = generateHeaders();
  let response = null;

  headers['portal'] = `PBN`;
  if (user.customer?.customerId) {
    headers['executing_operator_id'] = `uriSFB=${user.customer.customerId};uriRUC=3#null`;
  }
  const data = {
    executingOperatorId: user.customer.additionalCustomerType?.officialId,
    organizationOperatorId: { userName: user.customer.userName },
    otp: getRestObject(REST_OBJECT_TYPE.OTP, { profilingTransaction: "MER" }),
    establishment: getRestObject(REST_OBJECT_TYPE.ESTABLISHMENT, { ruc: user.establishment.ruc }),
    customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { token: null }),
    profile: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { profileType: { mnemonic: user.customer.userProfile } })
  };

  try {
    console.log("sendOtpRequest request: ", data);
    response = await postEncryptRequest(`${dinersUrl}/users/processOtpRequest/`, publicKey, data, headers);
    console.log("sendOtpRequest response: ", response);
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
};


export const verifyOtpRequest = async (otpValue, user, dinersUrl, publicKey) => {
  const headers = generateHeaders();
  headers['portal'] = `PBN`;
  let response = null;

  const data = {
    organizationOperatorId: {
      userName: user.userName
    },
    customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { customerId: user.customer.additionalCustomerType?.officialId, token: { value: '' } }),
    profile: getRestObject(REST_OBJECT_TYPE.PROFILE, { profileType: { mnemonic: user.customer.userProfile } }),
    otp: getRestObject(REST_OBJECT_TYPE.OTP, { otp: otpValue, profilingTransaction: "MER" }),
    establishment: getRestObject(REST_OBJECT_TYPE.ESTABLISHMENT, { ruc: user.establishment.ruc })
  };

  try {
    console.log("verifyOtpRequest request:", data);
    response = await postEncryptRequest(`${dinersUrl}/users/verifyOtp/`, publicKey, data, headers);
    console.log("verifyOtpRequest response:", response);
    if (response.Detail) {
      throw Error(response.Detail.errors?.error?.source);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
};