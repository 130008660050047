import userManager from './AuthConfig';

class AuthService {
    async getUser() {
        return await userManager.getUser();
    }

    async renewToken() {
        try {
            const user = await userManager.signinSilent();
            if (user) {
                return await this.getUser();
            }
        } catch (error) {
            console.error('Error renewing token:', error);
        }
    }
}

const authService = new AuthService();

export default authService;