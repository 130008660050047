import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../useDeviceInfo';
import useUserAccess from '../sesion/useUserAccess';
import useHomePageState from './useHomePageState';
import useHomePageAuth from './useHomePageAuth';
import useHomePageUserData from './useHomePageUserData';

const useHomePage = () => {
  const navigate = useNavigate();
  const deviceInfo = useDeviceInfo();
  const { userAccess, isBlocked, error } = useUserAccess(navigate);
  const [userUpdated, setUserUpdated] = useState(false);

  const {
    isOtpModalOpen,
    isLoading,
    alertDataModal,
    showAlert,
    setIsOtpModalOpen,
    setIsLoading
  } = useHomePageState();

  const { handleLogout } = useHomePageAuth(setIsLoading);

  const homePageContext = {
    setIsLoading,
    showAlert
  };

  const { updateUserData } = useHomePageUserData(homePageContext);

  useEffect(() => {
    if (error) {
      showAlert("error.unknown.title", "error.unknown.message");
    } else if (isBlocked) {
      showAlert("error.ip-blocked.title", "error.ip-blocked.message", false);
    } else if (userUpdated && isBlocked === false) {
      setIsOtpModalOpen(true);
    }

  }, [error, isBlocked, userUpdated, showAlert, setIsOtpModalOpen]);

  useEffect(() => {
    const fetchUserData = async () => {
      setUserUpdated(await updateUserData(userAccess, deviceInfo));
    };

    if (userAccess && deviceInfo) {
      fetchUserData();
    }
  }, [userAccess, deviceInfo, updateUserData]);

  return {
    isOtpModalOpen,
    isLoading,
    alertDataModal,
    isBlocked,
    handleLogout,
    showAlert,
    setIsOtpModalOpen,
    setIsLoading
  };
};

export default useHomePage;