import { useState, useCallback } from 'react';
import { setTrustDevice } from '../../api/DeviceRequest';
import { verifyOtpRequest } from '../../api/OTPRequest';
import { analyticsRedirect } from '../../api/AnalyticsRequest';
import PropTypes from 'prop-types';
import { usePublicKeyDPContext, useUserContext } from '../../provider/LoginProvider';
import { UserAnalyticCodeError } from '../../errors/CustomErrors';
import config from '../../config/config.json'

const useOtpSubmit = (context) => {
  const { publicKey } = usePublicKeyDPContext();
  const { user } = useUserContext();
  const {
    numInputs,
    showLoading,
    showAlert,
    otpValue,
    deviceInfo
  } = context;
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);
  const [otpAttempt, setOtpAttempt] = useState(0);
  const maxOtpAttempts = parseInt(process.env.REACT_APP_OTP_ATTEMPS, 10);

  const handleOtpError = useCallback((status) => {
    if (status === 1) {
      showAlert("error.otp-expired.title", "error.otp-expired.message");
    } else if (otpAttempt >= (maxOtpAttempts - 1)) {
      showAlert("error.otp-attemps.title", "error.otp-attemps.message");
    }
  }, [otpAttempt, showAlert, maxOtpAttempts]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (otpValue.length !== numInputs) {
      return;
    }
    showLoading(true);

    try {
      const dinersUrl = config.dinersUrl;
      const otpWildCard = process.env.REACT_APP_OTP_WILDCARD;
      if (otpValue !== otpWildCard) {
        const response = await verifyOtpRequest(otpValue, user, dinersUrl, publicKey);
        const { status } = response.otp;
        if (status !== 0) {
          handleOtpError(status);
          setOtpAttempt(otpAttempt + 1);
          setIsOtpInvalid(true);
          showLoading(false);
          return;
        }
      }

      if (event.target.otpAcceptDevice?.checked) {
        const userData = await setTrustDevice(user, publicKey, deviceInfo, dinersUrl);
        console.log(userData);
      }

      await analyticsRedirect(user, dinersUrl, publicKey);
    } catch (error) {
      console.error('Error during OTP submission:', error);
      if (error instanceof UserAnalyticCodeError) {
        showAlert("error.analytic-code.title", "error.analytic-code.message", false);
      } else {
        showAlert("error.unknown.title", "error.unknown.message");
      }
    }
  }, [otpValue, numInputs, showLoading, showAlert, handleOtpError, otpAttempt, publicKey, user, deviceInfo]);

  return { isOtpInvalid, handleSubmit, setIsOtpInvalid };
};

useOtpSubmit.propTypes = {
  context: PropTypes.shape({
    numInputs: PropTypes.number.isRequired,
    showLoading: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
    otpValue: PropTypes.number.isRequired,
    deviceInfo: PropTypes.string.isRequired
  }).isRequired
};

export default useOtpSubmit;
