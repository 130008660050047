import { useState, useEffect, useCallback } from 'react';
import { generateHeaders, getRestObject, REST_OBJECT_TYPE, postEncryptRequest } from './ApiService';
import config from '../config/config.json'

const useCustomerIpBlocked = (publicKey) => {
  const [isBlocked, setIsBlocked] = useState(null);
  const [error, setError] = useState(null);

  const checkCustomerIpBlocked = useCallback(async () => {
    if (!publicKey) return;

    const dinersUrl = config.dinersUrl;
    const headers = generateHeaders();
    const data = {
      customer: getRestObject(REST_OBJECT_TYPE.CUSTOMER, { systemUser: { userName: null } })
    };

    try {
      console.log("verifyCustomerIpBlocked request", data);
      const response = await postEncryptRequest(`${dinersUrl}/directusers/verifyCustomerIpBlocked/`, publicKey, data, headers);
      console.log("verifyCustomerIpBlocked response", response);
      const executionStatus = response?.dataExecutionTransaction?.executionTransactionStatus?.shortDesc;
      setIsBlocked(executionStatus !== 0);
    } catch (error) {
      console.error('Error fetching customer IP block status', error);
      setError(error);
    }
  }, [publicKey]);

  useEffect(() => {
    checkCustomerIpBlocked();
  }, [checkCustomerIpBlocked]);

  return { isBlocked, error };
};

export default useCustomerIpBlocked;
