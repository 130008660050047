import { postRequest } from './ApiService';

export const fetchPublicKey = async (dinersUrl) => {
    let response = null;
    try {
        const publicKey = await postRequest(`${dinersUrl}/directcall/singleSelectPublicKey/`, {}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        response = formatPublicKey(publicKey.data.publicKey.publicKey);
    } catch (error) {
        console.error('Error fetching public key:', error);
    }
    return response;
};

const formatPublicKey = (key) => {
    const cleanedKey = key.replace(/(\r\n|\n|\r|\s)/gm, '');
    
    const lines = cleanedKey.match(/.{1,64}/g);
    return `-----BEGIN PUBLIC KEY-----\r\n${lines.join('\r\n')}\r\n-----END PUBLIC KEY-----`;
};