import React, { useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';

const userContext = React.createContext();
const publicKeyDPContext = React.createContext();

export function useUserContext() {
    return useContext(userContext);
}

export function usePublicKeyDPContext() {
    return useContext(publicKeyDPContext);
}

export function LoginProvider({ children }) {
    const [user, setUser] = useState(null);
    const [publicKey, setPublicKey] = useState(null);


    const userValue = useMemo(() => ({ user, setUser }), [user]);
    const publicKeyDPValue = useMemo(() => ({ publicKey, setPublicKey }), [publicKey]);

    return (
        <userContext.Provider value={userValue}>
            <publicKeyDPContext.Provider value={publicKeyDPValue}>
                {children}
            </publicKeyDPContext.Provider>
        </userContext.Provider>
    );
}

LoginProvider.propTypes = {
    children: PropTypes.node.isRequired
};